<template>
  <div class="input" :class="disabled ? 'disable-input' : ''">
    <!-- Label -->
    <h5 v-if="multiSelectLabel" class="input-label">{{ multiSelectLabel }}</h5>

    <!-- Multiselect component - More info: https://primefaces.org/primevue/multiselect  -->
    <MultiSelect
      ref="primeMultiselectRef"
      v-model="selectedOption"
      aria-labelledby="multiselect"
      :class="validationErrorMessage ? 'multiselect-error' : null"
      :disabled="disabled"
      :options="options"
      :option-label="optionLabel"
      :option-value="optionValue"
      :placeholder="placeholder"
      :display="display"
      :input-id="id"
      @show="getOverlay(true)"
      @hide="getOverlay(false)"
      @input="onChange"
    >
      <!-- Input value -->
      <template #value="option">
        <div class="p-multiselect-label-option">
          <!-- If: Input has value -->
          <template v-if="option.value && option.value.length > 0">
            <!-- Case 1: Default/comma -->
            <div v-if="display === 'comma' || display === ''" class="p-multiselect-token-default">
              {{ setOptionInArray(option.value) }}
            </div>
            <!-- Case 2: Chip -->
            <template v-if="display === 'chip'">
              <div v-for="item in option.value" :key="item.key" class="p-multiselect-token">
                <!-- If: type include chipFlag -->
                <AppFlag v-if="findType('chipFlag')" class="country-flag mr-2">
                  <Component :is="getFlag(item)"></Component>
                </AppFlag>
                <!-- Default value -->
                <span class="p-multiselect-token-item">
                  {{ setOptionTitle(item, options) }}
                </span>
                <!-- If: Type include chipCloseButton -->
                <span
                  v-if="findType('chipCloseButton')"
                  class="p-multiselect-token-button"
                  @click.prevent.stop="removeChipItem(item)"
                  @keyPress.prevent.stop="removeChipItem(item)"
                >
                  <AppIcon size="12">
                    <IconClose />
                  </AppIcon>
                </span>
              </div>
            </template>
          </template>
          <!-- Else: Placeholder -->
          <div
            v-else
            :class="display === 'chip' ? 'multiselect-chip-placeholder' : 'multiselect-placeholder'"
          >
            {{ option.placeholder }}
          </div>
        </div>
        <!-- Arrow Icon -->
        <div
          :class="[
            'p-multiselect-trigger',
            `${pageName}-xe-multiselect-arrow`,
            {
              'input-no-arrow': !showArrow,
              'input-validation-error':
                validationErrorMessage || (serverError && serverError.length > 0),
            },
          ]"
          role="button"
          aria-haspopup="listbox"
          aria-label="button"
          :aria-expanded="hasOverlay"
        >
          <AppIcon
            class="p-multiselect-trigger-icon pi pi-chevron-down"
            aria-hidden="true"
            size="24"
          >
            <IconChevronUp></IconChevronUp>
          </AppIcon>
        </div>
      </template>

      <!-- Options -->
      <template #option="slotProps">
        <AppRipple>
          <!-- Set custom items based on type properties -->
          <!-- If: Type include flag -->
          <div v-if="findType('flag')" class="country-item">
            <AppFlag class="country-flag">
              <Component :is="getFlag(slotProps.option[optionValue])"></Component>
            </AppFlag>
            <span class="country-name-after-icon">{{ slotProps.option[optionLabel] }}</span>
          </div>
          <!-- Default options -->
          <div v-else>
            {{ slotProps.option[optionLabel] }}
          </div>
        </AppRipple>
      </template>
    </MultiSelect>
    <!-- Error message -->
    <span v-if="validationErrorMessage" class="validation-error-message type-helper">
      {{ validationErrorMessage }}
    </span>
    <!-- Max amount error message -->
    <span v-if="displayMaxAmountOfItems" class="multiselect-validation-error-max-amount">
      <AppIcon class="mr-1" size="14">
        <IconWarningFilled></IconWarningFilled>
      </AppIcon>
      {{ maxSelectedItemsMessage }}
    </span>
  </div>
</template>

<script>
import { onMounted, ref, watch } from '@vue/composition-api'

import MultiSelect from 'primevue/multiselect'
import AppIcon from '@en-ui/components/AppIcon/AppIcon'
import AppFlag from '@en-ui/components/AppFlag/AppFlag'
import AppRipple from '@en-ui/components/AppRipple/AppRipple'

import { IconClose, IconChevronUp, IconWarningFilled } from '@moneytransfer.ui/euronet-icons'
import * as EuronetFlags from '@moneytransfer.ui/euronet-flags'

export default {
  components: {
    MultiSelect,
    AppFlag,
    AppRipple,
    AppIcon,
    IconClose,
    IconChevronUp,
    IconWarningFilled,
  },
  extends: MultiSelect,
  props: {
    id: {
      type: String,
      default: 'default-id',
    },
    multiSelectLabel: {
      type: String,
      default: null,
    },
    pageName: {
      type: String,
      default: 'unknownpage',
    },
    serverError: {
      type: Array,
      default: () => [],
    },
    showArrow: {
      type: Boolean,
      default: true,
    },
    type: {
      type: [String, Array],
      default: null,
    },
    maxSelectedItems: {
      type: Number,
      default: null,
    },
    maxSelectedItemsMessage: {
      type: String,
      default: null,
    },
    validationErrorMessage: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const primeMultiselectRef = ref(null) // Ref for PrimeMultiselect component
    const selectedOption = ref(null) // Selected option by the user
    const hasOverlay = ref(false) // Overlay value
    const displayMaxAmountOfItems = ref(false)

    // Setup default values
    onMounted(() => {
      // Set saved option values
      const options = props.options.map((option) => option[props.optionValue])
      if (props.value && props.value.length > 0) {
        selectedOption.value = [...props.value].filter((option) => options.includes(option))
      }
    })

    // Watch and emit selected options
    watch(selectedOption, (options) => {
      // v-model value
      emit('input', options)

      // Calculate the overlay position based on current height of the main container
      // Note: To update the position is required to include a setTimeout function
      // due to a delay of the DOM to get the current container height value
      setTimeout(() => updateOverlayPosition(), 5)
    })

    // Custom behavior for multiselect component
    const onChange = (selectedOptions) => {
      // Set warning message if items are more than 10
      limitMaxItems(selectedOptions)
    }

    // Update overlay position
    const updateOverlayPosition = () => {
      const { overlay, container } = primeMultiselectRef.value.$refs

      // Case 1: Set position top dynamically using the current container height
      if (container && overlay) {
        const containerHeight = container.getBoundingClientRect().height
        overlay.style.top = `${containerHeight}px`
      }
    }

    // Limit and display max amount of selected options
    const limitMaxItems = (selectedOptions) => {
      // Case: check for selected options
      if (props.maxSelectedItems && selectedOptions) {
        // Listening for max amount to display error message
        displayMaxAmountOfItems.value = selectedOptions.length > props.maxSelectedItems

        // Case: if max amount message is shown, remove last array element
        if (displayMaxAmountOfItems.value) {
          const selectOptionsCopy = [...selectedOption.value]
          selectOptionsCopy.splice(-1)
          selectedOption.value = selectOptionsCopy
          // Hide options
          primeMultiselectRef.value.hide()
        }
      }
    }

    // Remove chip element
    const removeChipItem = (item) => {
      const options = [...selectedOption.value]
      selectedOption.value = options.filter((option) => option !== item)

      // Remove warning message if items are less than 10
      limitMaxItems(selectedOption.value)
    }

    // Set option based on value
    const setOptionTitle = (target, options) => {
      const title = options.find((option) => option[props.optionValue] === target)
      return title[props.optionLabel] || ''
    }

    // Set array style option
    const setOptionInArray = (items) => {
      const result = items.map((item) => {
        return props.options.find((option) => option[props.optionValue] === item)[props.optionLabel]
      })
      return result.toString()
    }

    // Set flag component using country code
    const getFlag = (flagCode) => {
      let flag = null

      if (flagCode) {
        flag = 'Flag' + flagCode.toUpperCase()
      }

      if (flag === 'FlagKV') {
        flag = 'FlagCV'
      }

      return EuronetFlags[flag] || null
    }

    // Find config type for multiselect
    const findType = (searchTerm) => {
      if (props.type instanceof Array) {
        const hasType = props.type.find((value) => value === searchTerm)
        return hasType
      }
      return props.type === searchTerm
    }

    // Get overlay status
    const getOverlay = (status) => {
      // Set overlay status
      hasOverlay.value = status

      // Check option height when status is true
      if (status) {
        updateOverlayPosition()
      }
    }

    return {
      selectedOption,
      hasOverlay,
      primeMultiselectRef,
      displayMaxAmountOfItems,
      getFlag,
      setOptionTitle,
      removeChipItem,
      findType,
      getOverlay,
      setOptionInArray,
      onChange,
    }
  },
}
</script>

<style lang="postcss">
.input {
  display: flex;
  flex-direction: column;
}

.p-multiselect {
  @apply rounded-lg;
  @apply p-2;
  @apply border border-blue-lighter;
  @apply w-full;
  @apply items-center;
  box-shadow: 0px 3px 15px rgba(0, 17, 51, 0.05);

  &.multiselect-error,
  &.input-validation-error {
    @apply border-red;
  }

  &.p-focus {
    @apply border-blue;
    @apply shadow-outline-input-orange;
    @apply border;
  }

  .p-highlight {
    @apply bg-tertiary-gray-darker;
  }

  .multiselect-chip-placeholder,
  .multiselect-placeholder {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    @apply text-tertiary-text;
  }

  .p-multiselect-trigger {
    &.input-validation-error {
      @apply text-red;
    }

    .pi-chevron-down {
      transform: rotate(-180deg);
      transition: all 270ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    &[aria-expanded='true'] {
      &.input-validation-error {
        @apply text-red;
      }
      @apply text-blue-text;
      .pi-chevron-down {
        transform: rotate(0deg);
      }
    }
  }

  /* This input should only target editable input. */
  input {
    @apply border-none outline-none;
  }

  .p-multiselect-label {
    @apply flex justify-between w-full;
    @apply leading-6 whitespace-normal;
    @apply text-base;
    @apply text-primary-text;

    .p-multiselect-token-default {
      @apply pt-1 pb-1;
    }

    .p-multiselect-token {
      @apply bg-tertiary-gray-darker;
      @apply py-0 px-2 mr-2 mb-1 mt-1 rounded-md;
      padding: 0 inherit;

      &-item {
        @apply mr-1;
        @apply text-xs leading-5 font-medium;
      }

      &-button {
        @apply cursor-pointer;
      }
    }

    &-container {
      @apply flex;
    }
  }

  .p-multiselect-panel {
    @apply text-left;
    @apply mt-2;
    @apply border border-transparent rounded-lg;
    @apply bg-white;
    max-width: 350px;
    @screen xs {
      max-width: 100%;
    }
    box-shadow: 0px 7px 24px rgba(0, 0, 0, 0.15);

    &.p-multiselect-panel--bottom-up {
      @apply bottom-14;
      top: unset !important; /* No tailwind equivalent */

      @screen sm {
        .p-multiselect-items-wrapper {
          @apply bottom-0;
        }
      }
    }

    .md-ripple {
      @apply px-4 py-2.5;
      @apply type-caption font-medium;

      &:hover {
        @apply bg-gray-bg;
      }

      .multiselect-option {
        @apply truncate;
      }

      .multiselect-option-subtitle {
        @apply type-caption-xs text-gray;
      }
    }

    .p-multiselect-items-wrapper {
      @apply rounded-lg;
      @apply h-auto !important;
      max-height: 300px;
    }

    .p-multiselect-items {
      @apply rounded-lg;
    }
  }

  .p-multiselect-loader {
    @apply h-40 w-full;
    @apply flex;
    @apply items-center justify-center;

    .no-options-message {
      @apply text-tertiary-text;
      @apply text-center;
    }
  }

  .p-multiselect-footer {
    @apply border-t border-blue-light;
  }

  .input-no-arrow {
    @apply hidden;
  }
}

.multiselect-validation-error-max-amount {
  @apply flex mt-1;
  @apply type-helper text-gray-primary;
}

.validation-error-message {
  @apply text-red-text;
  @apply mt-1;
}

svg.spinner {
  @apply text-blue !important;
}

.country-item {
  @apply flex flex-row items-center;

  .country-name-after-icon {
    @apply ml-2;
  }
}
</style>
