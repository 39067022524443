var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input",class:_vm.disabled ? 'disable-input' : ''},[(_vm.multiSelectLabel)?_c('h5',{staticClass:"input-label"},[_vm._v(_vm._s(_vm.multiSelectLabel))]):_vm._e(),_c('MultiSelect',{ref:"primeMultiselectRef",class:_vm.validationErrorMessage ? 'multiselect-error' : null,attrs:{"aria-labelledby":"multiselect","disabled":_vm.disabled,"options":_vm.options,"option-label":_vm.optionLabel,"option-value":_vm.optionValue,"placeholder":_vm.placeholder,"display":_vm.display,"input-id":_vm.id},on:{"show":function($event){return _vm.getOverlay(true)},"hide":function($event){return _vm.getOverlay(false)},"input":_vm.onChange},scopedSlots:_vm._u([{key:"value",fn:function(option){return [_c('div',{staticClass:"p-multiselect-label-option"},[(option.value && option.value.length > 0)?[(_vm.display === 'comma' || _vm.display === '')?_c('div',{staticClass:"p-multiselect-token-default"},[_vm._v(" "+_vm._s(_vm.setOptionInArray(option.value))+" ")]):_vm._e(),(_vm.display === 'chip')?_vm._l((option.value),function(item){return _c('div',{key:item.key,staticClass:"p-multiselect-token"},[(_vm.findType('chipFlag'))?_c('AppFlag',{staticClass:"country-flag mr-2"},[_c(_vm.getFlag(item),{tag:"Component"})],1):_vm._e(),_c('span',{staticClass:"p-multiselect-token-item"},[_vm._v(" "+_vm._s(_vm.setOptionTitle(item, _vm.options))+" ")]),(_vm.findType('chipCloseButton'))?_c('span',{staticClass:"p-multiselect-token-button",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.removeChipItem(item)},"keyPress":function($event){$event.preventDefault();$event.stopPropagation();return _vm.removeChipItem(item)}}},[_c('AppIcon',{attrs:{"size":"12"}},[_c('IconClose')],1)],1):_vm._e()],1)}):_vm._e()]:_c('div',{class:_vm.display === 'chip' ? 'multiselect-chip-placeholder' : 'multiselect-placeholder'},[_vm._v(" "+_vm._s(option.placeholder)+" ")])],2),_c('div',{class:[
          'p-multiselect-trigger',
          (_vm.pageName + "-xe-multiselect-arrow"),
          {
            'input-no-arrow': !_vm.showArrow,
            'input-validation-error':
              _vm.validationErrorMessage || (_vm.serverError && _vm.serverError.length > 0),
          } ],attrs:{"role":"button","aria-haspopup":"listbox","aria-label":"button","aria-expanded":_vm.hasOverlay}},[_c('AppIcon',{staticClass:"p-multiselect-trigger-icon pi pi-chevron-down",attrs:{"aria-hidden":"true","size":"24"}},[_c('IconChevronUp')],1)],1)]}},{key:"option",fn:function(slotProps){return [_c('AppRipple',[(_vm.findType('flag'))?_c('div',{staticClass:"country-item"},[_c('AppFlag',{staticClass:"country-flag"},[_c(_vm.getFlag(slotProps.option[_vm.optionValue]),{tag:"Component"})],1),_c('span',{staticClass:"country-name-after-icon"},[_vm._v(_vm._s(slotProps.option[_vm.optionLabel]))])],1):_c('div',[_vm._v(" "+_vm._s(slotProps.option[_vm.optionLabel])+" ")])])]}}]),model:{value:(_vm.selectedOption),callback:function ($$v) {_vm.selectedOption=$$v},expression:"selectedOption"}}),(_vm.validationErrorMessage)?_c('span',{staticClass:"validation-error-message type-helper"},[_vm._v(" "+_vm._s(_vm.validationErrorMessage)+" ")]):_vm._e(),(_vm.displayMaxAmountOfItems)?_c('span',{staticClass:"multiselect-validation-error-max-amount"},[_c('AppIcon',{staticClass:"mr-1",attrs:{"size":"14"}},[_c('IconWarningFilled')],1),_vm._v(" "+_vm._s(_vm.maxSelectedItemsMessage)+" ")],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }