<template>
  <Transition name="md-ripple" appear @after-enter="end">
    <span v-if="animating" />
  </Transition>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
export default {
  name: 'AppWave',
  props: {
    waveClasses: {
      type: Object,
      default: () => {},
    },
    waveStyles: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const animating = ref(false)
    onMounted(() => {
      animating.value = true
    })

    const end = () => {
      animating.value = false
      emit('md-end')
    }
    return {
      animating,
      end,
    }
  },
}
</script>

<style></style>
